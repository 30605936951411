<template>
    <mercur-card class="fill full-height-layout">
        <grid-header class="full-width-filters">
            Quotes
            <template slot="filters">
                <div class="filter-container">
                    <div class="filter">
                        <span>Assignee</span>
                        <pretty-select
                            :options="availableAssignees"
                            :value="quoteAssigneeId"
                            label="fullName"
                            :reduce="agent => agent.accountId"
                            @input="handleAssigneeFilter"
                            :disabled="organisationAccounts === null"
                            ></pretty-select>
                    </div>
                    <div class="filter">
                        <span>Delivery country</span>
                        <pretty-select
                            label="countryName"
                            :options="availableCountries"
                            :reduce="country => country.countryShortCode"
                            :value="countryFilter"
                            @input="handleDeliveryCountryFilter"
                        ></pretty-select>
                    </div>
                    <div class="filter">
                        <span>Date created</span>
                        <mercur-date-picker
                            v-model="dateRange"
                            :range-picker="true"
                            :ranges="additionalDateRanges"
                            autoApply
                        >Date range</mercur-date-picker>
                    </div>
                    <mercur-button class="btn btn-raised mt-3" @click="resetFilters">Reset filters</mercur-button>
                </div>
            </template>
        </grid-header>

        <template v-if="isAllowedTo('getAllSupplierQuotes')">
            <data-table
                data-test="QuotesOverviewTable"
                class="fill full-height-layout border mt-3"
                ref="grid"
                :options="options"
                :url="url"
                v-if="url"
            />
        </template>
        <p class="permission-message" v-else-if="url">Not allowed to see this view</p>

        <mercur-dialog :isOpen.sync="editQuoteDialogActive" class="dialog-old" v-if="isAllowedTo('updateSupplierQuote')">
            <div slot="header">
                <h3>Edit Quote</h3>
            </div>
            <div class="px-2">
                <p>Please enter the new quote start and end dates</p>

                <div>
                    <mercur-input v-model="$v.quoteEditParams.quoteStartDate.$model" required type="date">
                        Quote Start Date
                        <span slot="note" v-if="!$v.quoteEditParams.quoteStartDate.required">Start date is required</span>
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model="$v.quoteEditParams.quoteStartDate.$model" required type="time">
                        Quote Start Time
                        <span slot="note" v-if="!$v.quoteEditParams.quoteStartTime.required">Start time is required</span>
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model="$v.quoteEditParams.quoteEndDate.$model" required type="date">
                        Quote End Date
                        <span slot="note" v-if="!$v.quoteEditParams.quoteEndDate.required">End date is required</span>
                    </mercur-input>
                </div>

                <div>
                    <mercur-input v-model="$v.quoteEditParams.quoteEndTime.$model" required type="time">
                        Quote End Time
                        <span slot="note" v-if="!$v.quoteEditParams.quoteEndTime.required">Start time is required</span>
                    </mercur-input>
                </div>

            </div>

            <div slot="footer">
                <mercur-button class="btn btn-primary" @click="editQuoteDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow" @click="submitEditQuote" :disabled="$v.quoteEditParams.$invalid">Submit</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import regions from 'country-region-data'

import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
import CONFIG from '@root/config'
import PrettySelect from '../../components/utils/PrettySelect'

export default {
    name: 'QuotesOverview',
    components: { PrettySelect, DataTable, GridHeader },
    props: ['organisationAccounts', 'quoteAssigneeId'],
    mixins: [ validationMixin ],
    beforeRouteEnter (to, from, next) {
        if (!to.params.status) {
            next({
                ...to,
                params: {
                    status: CONFIG.STATUSSES.QUOTES[0].value,
                },
            })
            return
        }
        next()
    },

    data () {
        return {
            url: null,
            availableCountries: regions,
            countryFilter: null,
            dateRange: {
                startDate: '',
                endDate: '',
            },
            additionalDateRanges: {
                'Today': [new Date(new Date().setHours(1, 0, 0, 0)), new Date()],
                '1 day ago': [this.getDateRange(1), new Date()],
                '3 days ago': [this.getDateRange(3), new Date()],
                '7 days ago': [this.getDateRange(7), new Date()],
                '1 month ago': [this.getDateRange(30), new Date()],
            },
            options: {
                columns: {
                    'Quote #': {
                        field: 'quoteNumber',
                        link: (value, data) => {
                            return {
                                name: 'QuoteDetails',
                                params: {
                                    quoteId: data.quoteId,
                                    supplierQuoteId: data.supplierQuoteId,
                                    cartId: data.cartId,
                                },
                            }
                        },
                        valueGetter: ({ data }) => {
                            return data.quoteNumber || data.quoteId
                        },
                    },
                    'Title': {
                        field: 'quoteTitle',
                    },
                    'Start Date': {
                        field: 'quoteStartDate',
                        sortable: true,
                    },
                    'End Date': {
                        field: 'quoteEndDate',
                        sortable: true,
                    },
                    'Assignee': {
                        field: 'quoteAssigneeId',
                        sortable: true,
                        valueGetter: ({ data }) => {
                            if (!data.quoteAssigneeId || this.organisationAccounts === null) {
                                return null
                            }

                            return this.organisationAccounts.find(({ accountId }) => {
                                return accountId === data.quoteAssigneeId
                            }) || data.quoteAssigneeId
                        },
                        valueFormatter: ({ value }) => {
                            if (!value || typeof value === 'string') {
                                return value
                            }

                            return `${value.firstName} ${value.lastName}`
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'dateFrom': {
                        field: 'dateFrom',
                        hide: true,
                    },
                    'dateTo': {
                        field: 'dateTo',
                        hide: true,
                    },
                },
            },
            items: null,
            editQuoteDialogActive: false,
            quoteEditParams: {},
        }
    },

    validations: {
        quoteEditParams: {
            quoteStartDate: { required },
            quoteStartTime: { required },
            quoteEndDate: { required },
            quoteEndTime: { required },
        },
    },

    watch: {
        '$route.params.status': {
            handler () {
                this.setGridUrl()
            },
        },
        'quoteAssigneeId': {
            handler () {
                this.setGridUrl()
            },
        },
        'countryFilter': {
            handler () {
                this.setGridUrl()
            },
        },
        availableAssignees () {
            this.$refs.grid.gridOptions.api.redrawRows()
        },
        dateRange: {
            handler (value) {
                this.$refs.grid.applyFilter({
                    dateFrom: {
                        'filter': value.startDate.toISOString(),
                        'filterType': 'text',
                        'type': 'greaterThan',
                        'label': 'Date From',
                    },
                    dateTo: {
                        'filter': value.endDate.toISOString(),
                        'filterType': 'text',
                        'type': 'lessThan',
                        'label': 'Date To',
                    },
                })
            },
            deep: true,
        },
    },

    computed: {
        quotesOverviewStatus () {
            return CONFIG.STATUSSES.QUOTES.find(({ value }) => value === this.$route.params.status)
        },
        availableAssignees () {
            return [{
                fullName: 'All',
                accountId: null,
            }].concat(this.organisationAccounts)
        },
    },

    created () {
        this.setGridUrl()
    },

    methods: {
        resetFilters () {
            this.countryFilter = null
            this.quoteAssigneeId = null
            this.$refs.grid.applyFilter()
        },
        getDateRange (offset) {
            let today = new Date()
            let dateOffset = new Date()
            dateOffset.setDate(today.getDate() - offset)
            return dateOffset
        },
        handleAssigneeFilter (quoteAssigneeId) {
            this.$router.replace({
                params: {
                    quoteAssigneeId,
                },
            })
        },
        handleDeliveryCountryFilter (countryCode) {
            this.countryFilter = countryCode
        },
        setGridUrl () {
            setTimeout(() => {
                this.url = CONFIG.API.ROUTES.QUOTES.OVERVIEW
                    .replace('{quoteStatus}', this.quotesOverviewStatus.rawValue)
                    .replace('{quoteAssigneeId}', this.quoteAssigneeId || 'all')
                    .replace('{deliveryCountryCode}', this.countryFilter || 'all')
            }, 0)
        },
        openEditQuote (params) {
            const quoteDetails = {
                supplierQuoteId: params.supplierQuoteId,
                quoteStartDate: null,
                quoteStartTime: null,
                quoteEndDate: null,
                quoteEndTime: null,
            }

            this.quoteEditParams = {
                ...this.quoteEditParams,
                ...quoteDetails,
            }

            this.$v.$reset()
            this.editQuoteDialogActive = true
        },
        submitEditQuote () {
            if (!this.isAllowedTo('updateSupplierQuote')) {
                return
            }

            const url = CONFIG.API.ROUTES.QUOTES.UPDATE.replace('{supplierQuoteId}', this.quoteEditParams.supplierQuoteId)

            const quoteEditParamsMerged = {
                quoteStartDate: `${this.quoteEditParams.quoteStartDate} ${this.quoteEditParams.quoteStartTime}:00`,
                quoteEndDate: `${this.quoteEditParams.quoteEndDate} ${this.quoteEditParams.quoteEndTime}:00`,
            }

            const jobName = {
                url,
                params: quoteEditParamsMerged,
            }
            this.addJob(jobName)
            this.$api.post(url, quoteEditParamsMerged).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Quote edited successfully`,
                })
                this.editQuoteDialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Quote editing failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.filter-container {
    display: flex;

}
.filter {
    flex: 1 0 auto;
    color: #777777;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
}
</style>
